// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/home/arthur/Desktop/armtango/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/home/arthur/Desktop/armtango/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-armenia-js": () => import("/home/arthur/Desktop/armtango/src/pages/about-armenia.js" /* webpackChunkName: "component---src-pages-about-armenia-js" */),
  "component---src-pages-calendar-js": () => import("/home/arthur/Desktop/armtango/src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-contact-us-js": () => import("/home/arthur/Desktop/armtango/src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-djs-js": () => import("/home/arthur/Desktop/armtango/src/pages/djs.js" /* webpackChunkName: "component---src-pages-djs-js" */),
  "component---src-pages-index-js": () => import("/home/arthur/Desktop/armtango/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maestros-js": () => import("/home/arthur/Desktop/armtango/src/pages/maestros.js" /* webpackChunkName: "component---src-pages-maestros-js" */),
  "component---src-pages-orchestra-js": () => import("/home/arthur/Desktop/armtango/src/pages/orchestra.js" /* webpackChunkName: "component---src-pages-orchestra-js" */),
  "component---src-pages-packages-js": () => import("/home/arthur/Desktop/armtango/src/pages/packages.js" /* webpackChunkName: "component---src-pages-packages-js" */),
  "component---src-pages-venues-and-locations-js": () => import("/home/arthur/Desktop/armtango/src/pages/venues-and-locations.js" /* webpackChunkName: "component---src-pages-venues-and-locations-js" */),
  "component---src-pages-workshop-topics-js": () => import("/home/arthur/Desktop/armtango/src/pages/workshop-topics.js" /* webpackChunkName: "component---src-pages-workshop-topics-js" */),
  "component---src-pages-workshops-and-milongas-js": () => import("/home/arthur/Desktop/armtango/src/pages/workshops-and-milongas.js" /* webpackChunkName: "component---src-pages-workshops-and-milongas-js" */),
  "component---src-pages-yerevan-hotels-js": () => import("/home/arthur/Desktop/armtango/src/pages/yerevan-hotels.js" /* webpackChunkName: "component---src-pages-yerevan-hotels-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/arthur/Desktop/armtango/.cache/data.json")

